<template>
    <div class="appdetail">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="appdetail_div">
            <div class="appdetail_div_main">
                <div class="appdetail_div_main_left">
                    <img class="appphone" src="@/assets/home/LeftIPhoneNew.jpeg">
                </div>
                <div class="appdetail_div_main_right">
                    <p class="appdetail_div_main_right_title">萨恩课堂</p>
                    <p class="appdetail_div_main_right_explain">中国会计网旗下会计学习平台</p>
                    <div class="appdetail_div_main_right_upLoad">
                        <div class="appdetail_div_main_right_upLoad_code">
                            <img src="@/assets/home/loadQRcode.png" alt="">
                        </div>
                        <div class="appdetail_div_main_right_upLoad_btn">
                            <div class="appdetail_div_main_right_upLoad_btn_iphone">
                                <span>iphone下载</span>
                                <img src="@/assets/home/iphone_icon.png" alt="">
                            </div>
                            <div class="appdetail_div_main_right_upLoad_btn_android">
                                <span>Android下载</span>
                                <img src="@/assets/home/Android_icon.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'

export default {
  name: 'appdetail',
  components: {
    t_header,
    t_footer
  },
  data(){
    return{
      isUserInfo:false
    }
  },
  mounted(){},
  methods:{
    // 父传子
    getTodos(e){
        console.log(e)
        this.isUserInfo=e
    },
  }
}
</script>
